import envConfig from "@/config/envConfig";
import { QUERY_PAGES_SLUG, QUERY_TAGS_SLUG } from "@/graphql/query";
import { ArticleTagType, ArticleType } from "@/types/article";
import { MediaType } from "@/types/common";
import { InterviewType } from "@/types/interviews";
import { PageType } from "@/types/page";
import { QuoteDetailType } from "@/types/quotes";

export const fetchSearchData = async () => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/most-searched`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return await response.json();
  } catch (err) {
    console.log(err);
  }
};

export const fetchSearchResult = async ({
  searchQuery,
  limit,
  page,
}: {
  searchQuery: string;
  limit: number;
  page: number;
}) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/search?search=${searchQuery}&limit=${limit}&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
      },
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return await response.json();
  } catch (err) {
    console.error("Error in fetchSearchResult:", err);
    return err;
  }
};

export const fetchNewsByReporter = async ({
  limit,
  page,
  slug,
}: {
  limit: number;
  page: number;
  slug: string;
}) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/news/author/${slug}?&limit=${limit}&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
      },
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return await response.json();
  } catch (err) {
    console.error("Error in fetchSearchResult:", err);
    return err;
  }
};

export const fetchInterviewsByReporter = async ({
  limit,
  page,
  slug,
}: {
  limit: number;
  page: number;
  slug: string;
}) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/interviews/author/${slug}?&limit=${limit}&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
      },
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return await response.json();
  } catch (err) {
    console.error("Error in fetchSearchResult:", err);
    return err;
  }
};

export const fetchQuotesByReporter = async ({
  limit,
  page,
  slug,
}: {
  limit: number;
  page: number;
  slug: string;
}) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/quotes/author/${slug}?&limit=${limit}&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
      },
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return await response.json();
  } catch (err) {
    console.error("Error in fetchSearchResult:", err);
    return err;
  }
};

export const fetchTopBarData = async () => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/globals/topbar`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-cache",
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return {
      mainNavLinks: respData?.mainNavLinks?.map((item: { category: any }) => item.category) || [],
      trendingTopics: respData?.trendingTopics?.map((item: { tags: any }) => item.tags) || [],
      socialLinks: respData?.socialLinks || [],
      logoUrl: respData?.logo?.url || "",
      darkLogoUrl: respData?.darkLogo?.url || "",
    };
  } catch (err) {
    console.error("Error in fetchTopBarData:", err);
    return err;
  }
};

export const postLike = async ({
  id,
  type,
  isLiked,
}: {
  id: string;
  type: "news" | "quotes" | "interviews";
  isLiked: boolean;
}) => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/like-bookmark/like`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contentId: id,
        contentType: type,
        isLiked,
      }),
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData;
  } catch (err) {
    throw err;
  }
};

export const postBookmark = async ({
  id,
  type,
  isBookmarked,
}: {
  id: string;
  type: "news" | "quotes" | "interviews";
  isBookmarked: boolean;
}) => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/like-bookmark/bookmark`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contentId: id,
        contentType: type,
        isBookmarked,
      }),
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData;
  } catch (err) {
    throw err;
  }
};

export const fetchAllBookmarks = async () => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/like-bookmark/bookmark`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData as {
      data: {
        interviews: {
          data: InterviewType[];
          count: number;
        };
        quotes: {
          data: QuoteDetailType[];
          count: number;
        };
        news: {
          data: ArticleType[];
          count: number;
        };
      };
    };
  } catch (err) {
    throw err;
  }
};

export const fetchAllLiked = async () => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/like-bookmark/like`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData as {
      data: {
        interviews: {
          data: any[];
          count: number;
        };
        quotes: {
          data: any[];
          count: number;
        };
        news: {
          data: any[];
          count: number;
        };
      };
    };
  } catch (err) {
    throw err;
  }
};

export const fetchActivityStatus = async ({
  contentId,
  contentType,
}: {
  contentId: string;
  contentType: "quotes" | "interviews" | "news";
}) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/${contentType}/activity-status/${contentId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData as { isLiked: boolean; likesCount: number; isBookmarked: boolean };
  } catch (err) {
    throw err;
  }
};

export const fetchMedia = async ({ id }: { id: string }) => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/media/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData as MediaType;
  } catch (err) {
    throw err;
  }
};

export const fetchAllTagsSlug = async ({ limit = 10 }: { limit?: number }) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_TAGS_SLUG,
        variables: {
          limit,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.Tags?.docs || []) as ArticleTagType[];
  } catch (err) {
    console.log(err);
  }
};

export const fetchAllPagesSlug = async ({ limit = 10 }: { limit?: number }) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_PAGES_SLUG,
        variables: {
          limit,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.Pages?.docs || []) as PageType[];
  } catch (err) {
    console.log(err);
  }
};

export const fetchSpecialEvents = async () => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/globals/special-events`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-cache",
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return respData;
  } catch (err) {
    console.error("Error in fetchSpecialEvents:", err);
    return err;
  }
};