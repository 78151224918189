"use client";

import { useEffect, useRef, useState } from "react";

interface CustomImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
  phImg?: string;
}

const CustomImage = ({
  src,
  phImg = "/images/placeholder-ld.png",
  loading = "lazy",
  ...props
}: CustomImageProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const imgRef = useRef<HTMLImageElement>(null);

  const handleLoad = () => setIsLoading(false);
  const handleError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  useEffect(() => {
    if (!src) return;

    if (!imgRef.current?.complete) {
      setIsLoading(true);
    }
  }, [src]);

  return (
    <span className="relative w-full h-full">
      <img
        ref={imgRef}
        src={src && !hasError ? src : phImg}
        onLoad={handleLoad}
        onError={handleError}
        {...props}
        alt={
          props?.alt && props?.alt?.length > 0 ? props.alt : "Deshabhimani - ദേശാഭിമാനി ദിനപ്പത്രം"
        }
        loading={loading}
        style={{ visibility: isLoading ? "hidden" : "visible", backgroundColor: "#f2f2f2" }}
      />
      {isLoading && (
        <span className="absolute inset-0">
          <img
            src={phImg}
            loading={loading}
            {...props}
            alt={
              props?.alt && props?.alt?.length > 0
                ? props.alt
                : "Deshabhimani - ദേശാഭിമാനി ദിനപ്പത്രം"
            }
          />
        </span>
      )}
    </span>
  );
};

export default CustomImage;
