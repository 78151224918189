import { IconType } from "react-icons";
import { BiLogoFacebook } from "react-icons/bi";
import { BsInstagram, BsTwitterX, BsWhatsapp, BsYoutube } from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

export type SocialIconsKey =
  | "instagram"
  | "facebook"
  | "twitter"
  | "whatsapp"
  | "youtube"
  | "linkedin"
  | "mail";

export const socialIconsMap: { [key in SocialIconsKey]: IconType } = {
  instagram: BsInstagram,
  facebook: FaFacebookF,
  twitter: BsTwitterX,
  whatsapp: BsWhatsapp,
  youtube: BsYoutube,
  linkedin: FaLinkedinIn,
  mail: FiMail,
};

export const socialShareOptions = [
  {
    name: "Facebook",
    icon: socialIconsMap["facebook"],
    generateLink: (url: string) => `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    color: "#1d77f2",
  },
  {
    name: "Twitter",
    icon: socialIconsMap["twitter"],
    generateLink: (url: string, title: string) =>
      `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
    color: "#000000",
  },
  {
    name: "Whatsapp",
    icon: socialIconsMap["whatsapp"],
    generateLink: (url: string, title: string) =>
      `https://api.whatsapp.com/send?text=${title} ${url}`,
    color: "#26d366",
  },
  {
    name: "LinkedIn",
    icon: socialIconsMap["linkedin"],
    generateLink: (url: string) => `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
    color: "#1789d1",
  },
  {
    name: "Email",
    icon: socialIconsMap["mail"],
    generateLink: (url: string, title: string) =>
      `mailto:?subject=Check out this article: ${title}&body=Here is a link to an article I thought you might be interested in: ${url}`,
    color: "",
  },
];
