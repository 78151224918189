export const SEARCH_RESULT_NEWS_LIMIT = 15;
export const LATESTNEWS_RESULT_NEWS_LIMIT = 15;
export const SUBCATEGORY_PAGE_NEWS_LIMIT = 15;
export const TAG_PAGE_RESULT_LIMIT = 15;
export const SINGLE_EDITION_EPAPERS_LIMIT = 10;
export const QUOTE_PAGE_RESULT_LIMIT = 15;
export const INTERVIEWS_PAGE_RESULT_LIMIT = 15;
export const VISUAL_STORY_PAGE_LIMIT = 15;
export const SHORTS_PAGE_LIMIT = 15;
export const PHOTO_STORY_PAGE_LIMIT = 15;
export const MAX_COPY_TEXT_LENGTH = 100;
export const MAX_ITEMS_PER_SITEMPAP = 1000;
export const PHOTOGRAPHER_PAGE_RESULT_LIMIT = 11;
export const TWITTER_USERNAME = "@online_dbi";
export const WEBSITE_NAME = "Deshabhimani";
