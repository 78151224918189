import { MessageType } from "@/types/common";

import styles from "./MessageComponent.module.css";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineExclamation,
  AiOutlineInfo,
} from "react-icons/ai";

interface MessageComponentProps {
  message: MessageType;
}

export default function MessageComponent({ message }: MessageComponentProps) {
  return (
    <div className={`${styles.message}`}>
      <span
        className={`w-4 h-4 min-w-4 min-h-4 rounded-full flex items-center justify-center text-[10px] text-white  ${
          styles[message.type]
        }`}
      >
        {message.type === "info" && <AiOutlineInfo />}
        {message.type === "error" && <AiOutlineClose />}
        {message.type === "success" && <AiOutlineCheck />}
        {message.type === "warning" && <AiOutlineExclamation />}
      </span>
      <span>{message.content}</span>
    </div>
  );
}
