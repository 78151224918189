"use client";

import { MessageCategoryType, MessageType } from "@/types/common";
import { createContext, ReactNode, useContext, useState } from "react";
import MessageComponent from "../shared/MessageComponent/MessageComponent";

interface MessageContextType {
  showMessage: (type: MessageCategoryType, content: string) => void;
}

const MessageContext = createContext<MessageContextType | null>(null);

export default function MessageProvider({ children }: { children: ReactNode }) {
  const [messages, setMessages] = useState<MessageType[]>([]);

  const showMessage = (type: MessageCategoryType, content: string) => {
    const id = Date.now();
    const newMessage: MessageType = { id, type, content };

    setMessages(prev => [...prev, newMessage]);

    // Auto remove messages after 3 seconds
    setTimeout(() => {
      removeMessage(id);
    }, 3000);
  };

  const removeMessage = (id: number) => {
    setMessages(prev => prev.filter(msg => msg.id !== id));
  };

  return (
    <MessageContext.Provider value={{ showMessage }}>
      {children}

      <div className="fixed top-5 left-1/2 transform -translate-x-1/2 flex flex-col gap-[15px] z-50">
        {messages?.map((msg, index) => {
          return <MessageComponent key={index} message={msg} />;
        })}
      </div>
    </MessageContext.Provider>
  );
}

export const useMessage = () => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error("useMessage must be used within a MessageProvider");
  }
  return context;
};
