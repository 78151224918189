"use client";

import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { MdClose } from "react-icons/md";

interface SearchInputProps {
  query?: string;
  handleSearch?: () => void;
}

export default function SearchInput({ query, handleSearch }: SearchInputProps) {
  const [searchQuery, setSearchQuery] = useState("");

  const router = useRouter();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (searchQuery) {
      router.push(`/search?q=${searchQuery}`);

      if (handleSearch) handleSearch();
    }
  };

  useEffect(() => {
    setSearchQuery(query || "");
  }, [query]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="border border-[#D9D9D9] dark:border-[#7A7A7A] w-full max-w-[600px] h-[60px] rounded-xl mx-auto overflow-hidden flex items-center gap-4 pl-4">
        <span className="text-[18px] dark:text-white">
          <BsSearch />
        </span>
        <input
          type="text"
          className="w-full h-full bg-transparent outline-none dark:text-white"
          placeholder="Search here..."
          onChange={e => setSearchQuery(e.target.value)}
          value={searchQuery || ""}
          required
        />
        {searchQuery?.length ? (
          <span
            onClick={() => setSearchQuery("")}
            className="text-[18px] dark:text-white cursor-pointer"
          >
            <MdClose />
          </span>
        ) : (
          ""
        )}
        <div className="h-full p-1">
          <button
            className="uppercase bg-[#E02026] rounded-xl h-full text-white px-4 w-[120px] text-sm"
            aria-label="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  );
}
